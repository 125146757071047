import Form from "@/components/form/Form";
import Popup from "@/components/popup/Popup";
import PopupBody from "@/components/popup/PopupBody";
import Spinner from "@/components/Spinner";

const { forwardRef, useRef, useState, useImperativeHandle } = require("react");

const CallMePopup = forwardRef((props, ref) => {

  const popup = useRef()

  const [form,setForm] = useState({})
  const [processing,setProcessing] = useState(false)

  const show = (e) => {
    popup.current.show()
  }

  const hide = (e) => {
    popup.current.hide()
  }

  useImperativeHandle(ref, () => ({
    show, hide
  }))

  return <Popup ref={popup} title="Call Me!">
    <PopupBody>
      <Form className="form" button={true} id="call_me" name="Call Me" onSubmit={e => onSubmit(e)} style={{maxWidth: '420px'}}>
        <div className='form-cells'>
          <div className='form-cell'>
            <input type="text" name="phone" value={form.phone || ''} aria-label="Your Phone Number" placeholder="Your Phone Number" onChange={e => onFieldChange(e, 'phone')}></input>
          </div>
          <div className='form-cell'>
            <button type="submit" className={'button button-primary ' + (processing ? 'processing' : '')} disabled={processing}>
              <span>{ "Submit" }</span>
              { processing &&
              <Spinner></Spinner>
              }
            </button>
          </div>
          <div className='form-cell'>
            <div className="disclaimer" style={{paddingTop:0}}>By submitting this for you agree to receive a call from Werner Mazda to discuss protection products and details.</div>
          </div>
        </div>
      </Form>

    </PopupBody>
  </Popup>

})

CallMePopup.displayName = "CallMePopup"
export default CallMePopup
