'use client'

import { useEffect, useState } from "react"
import YoutubePlayer from "./YoutubePlayer"

export default function YoutubeHero(props) {
  
  // const urlParts = props.url.includes("/embed/") ? props.url.split("/embed/") : props.url.includes("/watch/") ? props.url.split("/watch/") : props.url.split("/watch?v=")
  // const videoId = urlParts[urlParts.length-1]
  // const embedParams = [
  //   // "si=RriMxyIsPM_W_OoZ",
  //   "list=" + videoId,
  //   "showinfo=0",
  //   "playsinline=1",
  //   "autoplay=1",
  //   "enablejsapi=1",
  //   "mute=1",
  //   "loop=1",
  //   // "origin=https%3A%2F%2Fuafni.ngrok.io",
  //   "widgetid=1"
  // ]
  // const url = "https://www.youtube.com/embed/" + videoId + "?" + embedParams.join("&amp;")
  // // si=" + props.partner_key + "&playlist=" + videoId + "
  // console.log(url)

  const [init,setInit] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') setInit(true)
  }, [])

  if (init) {
    return <YoutubePlayer {...props}></YoutubePlayer>
    // return <iframe src={url}></iframe>
  } else {
    return <></>
  }

}
