'use client'

import VehicleForm from "./partials/VehicleForm";
import { useEffect, useRef, useState } from "react";
import { CHAT, CUSTOMIZE, PHONE_CALL, PLAY, POPULAR, QUOTE, RECOMMEND, X } from "@/components/Icons";
import CallMePopup from "./partials/CallMePopup";
import { replacePlaceholders } from "@/utils/Helpers";
import useEventListener from "@/plugins/EventListener";
import Image from "next/image";
import GlobalVideoPlayer from "./partials/GlobalVideoPlayer";
import Popup from "@/components/popup/Popup";
import PopupBody from "@/components/popup/PopupBody";
import HelpInfoSection from "./partials/HelpInfoSection";
import Page from "./Page";
import { bundleIdPopular, bundleIdRecommended } from "./ProductsScreen";
import Footer from "./partials/Footer";

const steps = [
  { title: "Our Recommendation", subtext: "Protection Packages", icon: RECOMMEND, id: bundleIdRecommended },
  { title: "Most Popular", subtext: "Protection Packages", icon: POPULAR, id: bundleIdPopular },
  // { title: "Build Your Package", subtext: "Customize and Choose", icon: CUSTOMIZE },
]

export default function StartScreen(props) {

  const vehForm = useRef()
  const callPopup = useRef()
  const pageRef = useRef()
  const vehiclePopup = useRef()

  const legal = props.config.legal
  const page = props.page || {}

  const [chatCollapsed, setChatCollapsed] = useState(true)
  const [bundle, setBundle] = useState()

  const loaingProducts = props.searchParams?.v && props.searchParams?.m && props.searchParams?.z ? true : false

  const isConfigured = props.config.errors?.length == 0

  useEventListener("chat_collapsed", event => {
    setChatCollapsed(event.detail)
  })

  const openCall = (e) => {
    e.preventDefault()
    callPopup.current.show()
  }

  const openChat = (e) => {
    pageRef.current.openChat(e)
  }

  const openVehiclePopup = (e, step) => {
    e.preventDefault()
    setBundle(step)
    vehiclePopup.current.show(e)
  }

  let placeholderParams = {
    config: props.config
  }

  const gsub = (str) => {
    return replacePlaceholders(str, placeholderParams)
  }

  if (loaingProducts) return <section className="page-center top">
    <VehicleForm ref={vehForm} {...props} page={page} noDisc={true}></VehicleForm>
  </section>

  return <Page ref={pageRef} {...props} chatPanel={true} chatCollapsed={true}>
    <div className="l-sections">
      <section className="centered">
      </section>
      <section className="centered">
        <span dangerouslySetInnerHTML={{__html: gsub(page?.texts?.header_line1 || "Protecing your vehicle gives you piece of mind.") }}></span><br></br>
        <span dangerouslySetInnerHTML={{__html: gsub(page?.texts?.header_line2 || "Let's get you started.") }}></span>
      </section>
      { isConfigured && props.config.live &&
      <section className="extra-pad centered">
        <div className="product-bundle-steps">
          <div>
            {steps.map((step, index) => (
              <button aria-label={step.title} key={index} onClick={e => openVehiclePopup(e, step)}>{step.icon} {step.title}</button>
            ))}
          </div>
        </div>
      </section>
      }
      {!isConfigured &&
        <section className="centered">
            <div className="page-error">
              SOME CONFIGURATIONS ARE MISSING! Please contact support to fix this issue.
            </div>
        </section>
      }
      {isConfigured && !props.config.live &&
        <section className="centered">
            <div className="">
              <h2>We will be live soon.</h2>
              <h2>Stay tuned...</h2>
            </div>
        </section>
      }
      {isConfigured && (props.config.live || props.searchParams?.preview) &&
        <>
          <section>
            <div className="start-tiles">
              <div>
                <section>
                  <div className="flex flex-align-center">
                    <h1 style={{ fontSize: '1.5rem' }} className="align-center" dangerouslySetInnerHTML={{__html: gsub(page?.texts?.header_line1 || "Let's Find Your Vehicle") }}></h1>
                  </div>
                </section>
                <VehicleForm ref={vehForm} {...props} page={page} noDisc={true}></VehicleForm>
              </div>
              {chatCollapsed &&
                <button className="start-cta" aria-label="Call Us" onClick={e => openChat(e)}>
                  <div>{CHAT}</div>
                  <h2 dangerouslySetInnerHTML={{__html: gsub(page?.texts?.chat_title || "Chat With Us") }}></h2>
                  <span dangerouslySetInnerHTML={{__html: gsub(page?.texts?.chat_subtitle || "Have questions?<br></br>We have live representatives to help you with them.") }}></span>
                </button>
              }
              {props.config.features?.click_to_call &&
                <button className="start-cta" aria-label="Call Us" onClick={e => openCall(e)}>
                  <div>{PHONE_CALL}</div>
                  <h2>Call Me</h2>
                  <span>Would like to talk?<br></br>Get your answers over the phone.</span>
                </button>
              }
            </div>
          </section>
          <HelpInfoSection {...props}></HelpInfoSection>
          <section className="centered">
            <div className="product-column">
              <div className="disclaimer" dangerouslySetInnerHTML={{ __html: replacePlaceholders(legal.general, { config: props.config }) }}>
              </div>
            </div>
          </section>
        </>
      }
      <section className="product-column">
        <Footer {...props}></Footer>
      </section>
      <CallMePopup ref={callPopup} {...props}></CallMePopup>
      <Popup ref={vehiclePopup} title={ bundle?.title } className={'popup-vehicle'}>
        <PopupBody>
          { bundle &&
          <VehicleForm {...props} update={true} ymmt={true} tab={"ymmt"} message={"Before we can recommend products, please enter your vehicle."} bundle={bundle} buttonLabel={ "Show " + bundle?.title }></VehicleForm>
          }
        </PopupBody>
      </Popup>
    </div>
  </Page>


}