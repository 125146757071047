  'use client'

import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"

const YoutubePlayer = forwardRef((props,ref) => {

  const urlParts = props.url.includes("/embed/") ? props.url.split("/embed/") : props.url.includes("/watch/") ? props.url.split("/watch/") : props.url.split("/watch?v=")
  const videoId = urlParts[urlParts.length-1]

  // const [player,setPlayer] = useState()
  let player = null

  window.onYouTubeIframeAPIReady = () => {
    player = new YT.Player('yt_video_player', {
      height: '100%',
      width: '100%',
      videoId: videoId,
      playerVars: {
        'playlist': videoId,
        'showinfo': 0,
        'playsinline': 1,
        'autoplay': 1,
        'controls': 0,
        'enablejsapi': 1,
        'mute': 1,
        'loop': 1,
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  }

  const play = (url) => {
  }

  const onPlayerReady = (event) => {
    event.target.playVideo();
  }

  const onPlayerStateChange = (event) => {
    if (event.data == YT.PlayerState.ENDED) {
      player.seekTo(0);
      player.playVideo();
    }
  }

  const stop = () => {
    player.stopVideo()
    player.clearVideo()
  }

  useImperativeHandle(ref, () => ({
    play, stop
  }))

  return <>
    <script src="https://www.youtube.com/player_api" async></script>
    <div id="yt_video_player"></div>
  </>

})

YoutubePlayer.displayName = "YoutubePlayer"
export default YoutubePlayer
